import React from "react";
import { graphql, Link } from "gatsby";
import Image from "gatsby-image";
import Layout from "../components/Layout";
import Typograghy from "@material-ui/core/Typography";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import Seo from "../components/Seo";

// import styled from "styled-components";
// import BackgroundImage from "gatsby-background-image";
// import Helmet from "react-helmet";
// // import { BLOCKS, MARKS } from "@contentful/rich-text-types";

import { useTheme, makeStyles } from "@material-ui/core/styles";
const useStyle = makeStyles((theme) => ({}));

const BlogTemplate = ({ data }) => {
  const classes = useStyle();
  const theme = useTheme();
  // const HeroBackgroundImage = styled(BackgroundImage)`
  //   width: 100%;
  //   height: 100vh;
  //   opacity: 1 !important;
  //   background-size: cover;
  //   background: linear-gradient(
  //     90.26deg,
  //     rgba(3, 113, 227, 0.9) 17.75%,
  //     rgba(238, 169, 64, 0.61) 99.89%
  //   );
  //   background-size: cover;
  // `;

  console.log(data.blog.ads728Top.url);
  const imageData = data.blog.thumbnail.fluid;
  return (
    <>
      <Seo title={data.blog.title} />
      <Layout>
        <main className="blogPage">
          <div className="blog-image-cover-container">
            <Image className="blog-image-cover" fluid={imageData} />

            {/* <HeroBackgroundImage fluid={data.blog.thumbnail.fluid} /> */}
          </div>
          <Link to={data.blog.ads728Top.url} className="ads-728">
            <Image fixed={data.blog.ads728Top.ads.fixed} />
          </Link>

          <div className="blogContainer">
            <div className="blogContentContainer">
              <div className="blogContent">
                <div className="blogContentText">
                  <Typograghy
                    component="h1"
                    variant="h2"
                    style={{ marginBottom: "20px" }}
                  >
                    {data.blog.title.toUpperCase()}
                  </Typograghy>
                  <Typograghy
                    paragraph={false}
                    variantMapping={{
                      h1: "h1",
                      h2: "h2",
                      h3: "h3",
                      h4: "h4",
                      h5: "h5",
                      h6: "h6",
                      subtitle1: "h6",
                      subtitle2: "h6",
                      body1: "div",
                      body2: "div",
                      inherit: "div",
                    }}
                  >
                    {documentToReactComponents(data.blog.content.json)};
                  </Typograghy>
                </div>
              </div>
              <div className="blogContent">
                <div className="blogContentText">
                  <div className="blogAuthor">
                    <Image fluid={data.blog.author.image.fluid} />

                    <div className="blogAuthorText">
                      <Typograghy variant="h6" style={{ fontWeight: "700" }}>
                        {data.blog.author.name}
                      </Typograghy>
                      <Typograghy>{data.blog.author.description}</Typograghy>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="blogslidbar">
              <Typograghy
                component="h5"
                variant="h6"
                style={{ margin: "20px " }}
              >
                Publicité
              </Typograghy>
              <Link to={data.blog.ads300250.url} className="ads-300">
                <Image fixed={data.blog.ads300250.ads.fixed} />
              </Link>
            </div>
          </div>
          <Link to={data.blog.ads728Top.url} className="ads-728">
            <Image fixed={data.blog.ads728Top.ads.fixed} />
          </Link>
        </main>
      </Layout>
    </>
  );
};

export const query = graphql`
  query GetSingleBlog($slug: String) {
    blog: contentfulBlog(slug: { eq: $slug }) {
      slug
      title
      category {
        name
      }
      author {
        name
        description
        image {
          fluid {
            ...GatsbyContentfulFluid
          }
        }
      }

      thumbnail {
        fluid {
          ...GatsbyContentfulFluid
        }
      }

      ads728Top: ads728Top {
        ads {
          fixed(width: 728, height: 90) {
            ...GatsbyContentfulFixed
          }
        }
        url
      }
      ads300250: ads300250 {
        ads {
          fixed(width: 300) {
            ...GatsbyContentfulFixed
          }
        }
        url
      }

      content {
        id
        json
      }
    }
  }
`;

export default BlogTemplate;
